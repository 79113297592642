import Image from 'next/legacy/image';
import React from 'react';

import CartMenuButtons from '@/components/cart/TopButtons/CartMenuButtons';
import Button from '@/components/common/Button/Button';
import { Mode } from '@/utils/constants';

export type EmptyCartType = {
  closeCart: () => void;
  openMealPlan: () => void;
  isMenuPage?: boolean;
  mode: Mode;
};

// Component for the subscription empty state
const SubscriptionEmptyState = ({
  closeCart,
  openMealPlan,
  mode,
  isMenuPage = false,
}: EmptyCartType) => (
  <div className="flex min-h-[80vh] flex-col items-center justify-center">
    <div className="flex flex-col items-center justify-center">
      <Image
        src="/assets/images/svg/delivery_day_time.svg"
        alt="Delivery Day Time"
        width={48}
        height={48}
      />
      <div className="pt-6">
        <p className="text-center font-interBold text-base leading-6 text-grey-dark">
          You have no items in your subscription.
        </p>
      </div>
      <div className="pt-6">
        <p className="w-72 text-center font-interMedium text-base leading-relaxed text-grey-dark">
          Add more items or discover the right meal plan that suits your taste
          and goals.
        </p>
      </div>
      <div className="flex items-center justify-center space-x-4 bg-white py-4 pt-6">
        <CartMenuButtons
          closeCart={closeCart}
          openMealPlan={openMealPlan}
          isMenuPage={isMenuPage}
          mode={mode}
        />
      </div>
    </div>
  </div>
);

// Component for the cart empty state
const CartEmptyState = ({ closeCart }: EmptyCartType) => {
  return (
    <div className="flex w-full flex-col items-center justify-center">
      <div className="pt-16">
        <Image
          src="/assets/images/svg/Cart_Disabled.svg"
          alt="Cart Basket"
          width={46.1}
          height={45.7}
        />
      </div>
      <div className="pt-6">
        <p className="font-interMedium text-sm text-grey-dark">
          You have no items in your cart
        </p>
      </div>
      <div className="pt-16">
        <Button
          type="button"
          link="/menu/meals"
          theme="secondary"
          onClick={closeCart}
        >
          <span className="p-1 font-interMedium text-base text-rhubarb-dark">
            Browse Full Menu
          </span>
        </Button>
      </div>
      <div className="relative flex w-full items-center px-12 py-8">
        <div className="grow border-t border-grey"></div>
        <span className="mx-4 shrink font-interMedium text-sm leading-none text-black">
          or
        </span>
        <div className="grow border-t border-grey"></div>
      </div>
      <div className="px-12">
        <p className="text-center font-interMedium text-[21px] leading-7 text-black">
          Discover the right meal<br></br>plan that suits your taste<br></br>and
          goals
        </p>
      </div>
      <div className="pb-4 pt-6">
        <Button
          type="button"
          theme="primary"
          link="/meal-plan?code=SAVE120"
          onClick={closeCart}
        >
          <span className="p-1 font-interMedium text-base">Get Started</span>
        </Button>
      </div>
    </div>
  );
};

// Main component
const EmptyCart: React.FC<EmptyCartType> = (props: EmptyCartType) => {
  if (props.mode === Mode.EDIT_SUBSCRIPTION) {
    return <SubscriptionEmptyState {...props} />;
  }

  return <CartEmptyState {...props} />;
};

export default EmptyCart;
